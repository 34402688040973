import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Container } from '../layout/layoutComponents';
import Logo from '../header/logo'

const FooterOutter = styled.div`
  margin: 0 auto;
  background-color: #8D8D8D;
  min-height: 412px;
  width: 100%;
`
const FooterInner = styled.footer`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px 30px;
  color: #fff;
  @media (min-width: 1090px) {
    padding: 80px 90px;
  }

`

const FooterItem = styled.div`
  padding: 0 10px;
  p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    margin: 0;
    padding: 0;
  }
`

const FooterHeadline = styled.h6`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 5px;
`
const StyledList = styled.ul`
  list-style: none;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  a {
    text-decoration: none;
    color: #fff;
    &:hover {
      cursor: pointer;
    }
  }
  
  li {
    margin: 0;
    padding: 0;
    letter-spacing: 1.2;
  }
`
const FooterLink = styled(Link)`
  text-decoration: none;
  color: #fff;
`

const Footer = () => (
  <FooterOutter>
    <Container>
      <FooterInner>
        <FooterItem>
          <FooterHeadline>Who we are</FooterHeadline>
          <StyledList>
            <li><FooterLink to="/#transformation">Avance Consulting</FooterLink></li>
            <li><FooterLink to="/dare-to-grow">Thor Ventures</FooterLink></li>
            <li><FooterLink href="/move-towards-success">AVANCE Academy</FooterLink></li>
          </StyledList>
        </FooterItem>
        <FooterItem>
          <FooterHeadline>Cases</FooterHeadline>
          <StyledList>
            <li><FooterLink to='/bus-manufac'>Lean Manufacturing</FooterLink></li>
            <li><FooterLink to='/divo'>Lean Logistics</FooterLink></li>
            <li><FooterLink to='/elsa'>Lean Administration</FooterLink></li>
          </StyledList>
        </FooterItem>
        <FooterItem>
          {/* <FooterHeadline>Sectors We Boost</FooterHeadline> */}
          <FooterHeadline>Find out more about...</FooterHeadline>
          <StyledList>
          <li><FooterLink to='/food'>Agile Culture</FooterLink></li>
            <li><FooterLink to='/health'>Integrated Supply chain Management</FooterLink></li>
            <li><FooterLink to='/utilities'>Lean Asset Management</FooterLink></li>
            <li><FooterLink to='/logistic'>Effective Sales Management</FooterLink></li>
            <li><FooterLink to='/retail'>Digitized Controlling and Finance</FooterLink></li>
            <li><FooterLink to='/banking-insurance'>Performance Management</FooterLink></li>

            {/* <li><FooterLink to='/food'>Food Industry</FooterLink></li>
            <li><FooterLink to='/utilities'>Utilities</FooterLink></li>
            <li><FooterLink to='/health'>Health Care Sector</FooterLink></li>
            <li><FooterLink to='/logistic'>Logistics</FooterLink></li>
            <li><FooterLink to='/retail'>Retail</FooterLink></li>
            <li><FooterLink to='/banking-insurance'>Banking & Insurance</FooterLink></li>
            <li><FooterLink to='/building-construction'>Building & Construction</FooterLink></li>
            <li><FooterLink to='/automotive'>Discrete Manufacturing</FooterLink></li> */}
          </StyledList>
        </FooterItem>
        <FooterItem>
          <FooterHeadline>Contact & Privacy</FooterHeadline>
          <StyledList>
            <li><FooterLink to='/contact'>Contact</FooterLink></li>
            <li><FooterLink to='/imprint'>Impressum</FooterLink></li>
            <li><FooterLink to='/privacy'>Privacy</FooterLink></li>
            <li><FooterLink to='/disclaimer'>Disclaimer</FooterLink></li>
          </StyledList>
        </FooterItem>
        <FooterItem>
          <Logo />
          <FooterHeadline>Avance Moving Forward GMBH</FooterHeadline>
          <p>Stargarder Straße 55a</p>
          <p>10437 Berlin</p>
        </FooterItem>
      </FooterInner>
    </Container>
  </FooterOutter>
)


export default Footer