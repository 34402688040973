import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';


const Logo = () => (
  <StaticQuery
    query={graphql`
    query logo {
      craftLogoGlobalSet {
        image {
          url
        }
        name
        imageAlt
      }
    }
    `}

    render={({craftLogoGlobalSet}) => (
      <Link to="/" style={{zIndex: `6000`}}>
        <img src={craftLogoGlobalSet.image[0].url} alt={craftLogoGlobalSet.imageAlt} style={{height:'53px', zIndex: `6000`}}/>
      </Link>
    )}

  />
);

export default Logo