import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout/layout"
import { Container } from "../components/layout/layoutComponents"
import { typo, colors } from "../components/utils"
import leftpic from "./left.png"
import rightpic from "./right.png"

const BlackRing = styled.div`
  overflow: hidden;
  @media (min-width: 1298px) {
    display: block;
    position: absolute;
    top: -150px;
    right: -600px;
    width: 550px;
    height: 550px;
    background: url(${rightpic});
    background-size: cover;
  }
  @media (min-width: 1410px) {
    width: 650px;
    height: 650px;
  }
`
const OrangeRing = styled.div`
  display: none;
  @media (min-width: 1298px) {
    display: block;
    position: absolute;
    bottom: -100px;
    left: -600px;
    width: 550px;
    height: 550px;
    background: url(${leftpic});
    background-size: cover;
  }
  @media (min-width: 1410px) {
    width: 650px;
    height: 650px;
  }
`
const Hero = styled.div`
  margin: 0 auto;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 360px;
  @media (min-width: 720px) {
    height: 535px;
    padding: 35px;
  }
  @media (min-width: 1024px) {
    height: 835px;
  }
`

const BlogHeader = styled.h1`
  margin: 0;
  margin-bottom: 15px;
  align-self: flex-end;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 4px;
  color: #3c3c3b;
  text-transform: uppercase;
  @media (min-width: 516px) {
    font-size: 46px;
    line-height: 52px;
  }
`
const StyledArticle = styled.article`
  position: relative;
  max-width: 1140px;
  margin: 0 auto;
  padding: 50px 35px;
  font-size: 20px;
  @media (min-width: 720px) {
    padding: 70px 35px;
  }
  h3 {
    font-size: ${typo.fontsubline};
    font-weight: ${typo.bold};
    line-height: ${typo.linesubline};
    color: ${colors.orange};
    text-transform: uppercase;
    margin: 0;
  }
  h3 {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 1.2px;
    margin: 0;
  }
`
const StyledLink = styled(Link)`
  text-decoration: none;
  color: #f28e00;
  line-height: 1.2;
  font-size: 20px;
`
const ArticleText = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 720px) {
    margin-top: 40px;
  }
  h2 {
    font-size: ${typo.fontdefault};
    font-weight: ${typo.regular};
    line-height: ${typo.linedefault};
    color: ${colors.orange};
    text-transform: uppercase;
    margin: 0;
  }
  p {
    font-size: ${typo.fontdefault};
    font-weight: ${typo.regular};
    line-height: ${typo.default};
    color: ${colors.default};
    margin: 0;
    margin-bottom: 30px;
  }
  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: ${typo.fontdefault};
    font-weight: ${typo.regular};
    line-height: ${typo.default};
    color: ${colors.default};
    list-style: none;
    li {
      min-width: 40%;
      position: relative;
      margin-bottom: 15px;
      &:nth-child(1) {
        margin-right: 190px;
      }
      &:nth-child(3) {
        margin-right: 190px;
      }
    }
    li:before {
      content: "";
      position: absolute;
      top: 8px;
      left: -39px;
      height: 13px;
      width: 13px;
      background-color: ${colors.orange};
    }
  }
`

export default function BlogsTemplate({ pageContext: { data } }) {
  const content = data.articleRight
  return (
    <Layout>
      <Container>
        <Hero style={{ backgroundImage: `url(${data.image[0].url})` }} />
      </Container>
      <Container style={{ position: `relative`, overflow: `hidden` }}>
        <StyledArticle>
          <OrangeRing />
          <BlackRing />
          <div style={{ marginBottom: `25px` }}>
            <StyledLink to="/blog">&#60; Back to Blog</StyledLink>
          </div>
          <BlogHeader>{data.title}</BlogHeader>
          <ArticleText dangerouslySetInnerHTML={{ __html: content }} />
        </StyledArticle>
      </Container>
    </Layout>
  )
}
