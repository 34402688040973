export default {
    regular: '300',
    bold: '500',
    xbold: '700',
    // sizes
    fontheadline: '60px',
    fontsubline: '30px',
    fontdefault: '20px',
    fontsmall: '16px',
    // lineHeight
    lineheadline: '79px',
    linesubline: '38px',
    linedefault: '28px',
    linesmall: '24px',
    // letterSpacing
    spacingdefault: '1.2px',
    spacinglarge: '1.8px',
    spacingxlarge: '3.6px',
};
