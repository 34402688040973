import React from 'react';
import styled from 'styled-components';
import { Container } from "../layout/layoutComponents"
import Logo from './logo';
import Burger from './burgerEN';

const HeaderOutter = styled.div`
  position: fixed;
  margin: 0;
  padding-top: 10px;
  width: 100%;
  z-index: 9999;
  align-items: center;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
`

const HeaderInner = styled.header`
  background: #fff;
  display: flex;
  flex-wrap: no-wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
`

const Header = () => (
    <HeaderOutter>
      <Container>
        <HeaderInner>
          <Logo />
          <Burger />
        </HeaderInner>
      </Container>
    </HeaderOutter>
      
)

export default Header;