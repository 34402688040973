import { typo, colors } from '../utils'

import styled from "styled-components"

const handleColorType = color => {
  switch (color) {
    case "blue":
      return "#2A677A";
    case "orange":
      return "#F28E00";
    default:
      return "#3C3C3B";
  }
};

export const StyledButton = styled.button`
  display: flex;
  z-index: 5000;
  margin-top: 10px;
  min-width: 115px;
  padding: 0;
  background: transparent;
  border: 1px solid ${colors.orange};
  color: ${colors.orange};
  font-family: "Roboto", sans-serif;
  font-weight: ${typo.thin};
  font-size: 13px;
  line-height: ${typo.linedsmall};
  letter-spacing: ${typo.spacingdefault};
  text-transform: uppercase;
  box-shadow: 0px 3px 6px #00000029;
  &:hover {
    cursor: pointer;
    transition: color 0.3s ease;
    color: #fff;
    transition: background-color 0.3s ease;
    background-color: ${colors.orange};
  }
  a {
    padding: 8px 12px;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: ${colors.orange};
    font-size: 13px;
    &:hover {
      transition: color 0.3s ease;
      color: #fff;
    }
  }
`

export const MainWrapper = styled.div`
  margin: 0;
  display: flex;
  min-height: 100vh;
  min-width: 100%;
  flex-direction: column;
  background-color: #fff;
`
export const StyledSection = styled.section`
  padding: 35px 10px;
  @media (min-width: 720px) {
    padding: 100px 0;
  }
`
export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 1640px;
  padding: 0px 1.0875rem;
  padding-top: 0;
`

export const Headline = styled.h2`
  font-size: 40px;
  line-height: 49px;
  padding: 0;
  margin: 0;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: ${typo.spacingdefault};
  text-transform: uppercase;
  color: ${colors.default};
  @media (min-width: 425px) {
    font-size: 36px;
    line-height: 38px;
  }
  @media (min-width: 720px) {
    font-size: 50px;
    line-height: 54px;
  }
  @media (min-width: 1140px) {
    font-size: ${typo.fontheadline};
    line-height: ${typo.lineheadline};
  }
`;

export const Subline = styled.h3`
  font-size: 24px;
  line-height: 28px;
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 1.8px;
  color: ${colors.orange};
  text-transform: uppercase;
  margin-bottom: 30px;
  @media (min-width: 425px) {
    font-size: 26px;
    line-height: 30px;
  }
  @media (min-width: 720px) {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 59px;
  }
  @media (min-width: 1140) {
    font-size: ${typo.fontsubline};
    line-height: ${typo.linesubline};
    letter-spacing: ${typo.spacinglarge};
  }
`;

export const Paragraph = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: ${typo.spacingdefault};
  color: ${colors.default};
  color: ${({ color }) => handleColorType(color)};
  font-weight: ${props => props.bold ? "500" : "300"};
  text-align: start;
  @media (min-width: 786px) {
    font-size: ${typo.fontsdefault};;
    line-height: ${typo.linedefault};
  };
  @media (min-width: 1240px) {
    text-align: ${props => props.center ? "center" : "start"};
    text-align: ${props => props.end ? "end" : "start"};
  }
`

export const H4 = styled.h4`
  margin: 0;
  text-transform: uppercase;
  padding: 0;
  letter-spacing: 1.2px;
  line-height: 28px;
  font-weight: 500;
  color: ${colors.default};
  color: ${({ color }) => handleColorType(color)};
  text-align: start;
  @media (min-width: 786px) {
    font-size: 20px;
    line-height: 28px;
  };
  @media (min-width: 1240px) {
    text-align: ${props => props.center ? "center" : "start"};
    text-align: ${props => props.end ? "end" : "start"};
  }
`
