import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import styled from "styled-components"
// import LangToggle from './langToggle';

const Nav = styled.nav`
  z-index: 5000;
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
  z-index: 5000;
  background-color: #fff;
  @media (max-width: 1024px) {
    flex-flow: column nowrap;
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
  }
`

const NavWrapper = styled.ul`
  background-color: #fff;
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  padding-inline-end: 0;
  padding-inline-start: 0;
  @media (max-width: 1024px) {
    flex-flow: column nowrap;
    padding-left: 2rem;
  }
`
const NavItem = styled.li`
  text-transform: uppercase;
  padding-right: 45px;
`

const NavLink = styled(AnchorLink)`
  color: #3c3c3b;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 0;
  cursor: pointer;
  transition: color 0.2s ease-in;
  &:hover {
    color: #010101;
  }
`

const Navigation = ({ open }) => (
  <Nav open={open}>
    <NavWrapper>
      <NavItem>
        <NavLink to={"/#how"} title="how">
          How
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink to={"/#cases"}>cases</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to={"/#businesses"}>Industries</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to={"/#contact"}>Contact</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to={"/blog"}>Blog</NavLink>
      </NavItem>
    </NavWrapper>
    {/* <LangToggle /> */}
  </Nav>
)

export default Navigation
